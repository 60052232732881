@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();
$yallo-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$violet-palette,
      tertiary: mat.$violet-palette,
      use-system-variables: true,
    ),
    typography: (
      plain-family: 'Nunito, sans-serif',
      brand-family: 'Nunito, sans-serif',
    ),
  )
);

html {
  @include mat.core-theme($yallo-theme);

  @include mat.autocomplete-theme($yallo-theme);
  @include mat.button-theme($yallo-theme);
  @include mat.button-toggle-theme($yallo-theme);
  @include mat.datepicker-theme($yallo-theme);
  @include mat.dialog-theme($yallo-theme);
  @include mat.expansion-theme($yallo-theme);
  @include mat.fab-theme($yallo-theme);
  @include mat.form-field-theme($yallo-theme);
  @include mat.icon-theme($yallo-theme);
  @include mat.input-theme($yallo-theme);
  @include mat.select-theme($yallo-theme);
  @include mat.table-theme($yallo-theme);
  @include mat.menu-theme($yallo-theme);
  @include mat.tabs-theme($yallo-theme);
  @include mat.radio-theme($yallo-theme);
  @include mat.progress-spinner-theme($yallo-theme);

  @include mat.system-level-colors($yallo-theme);
  @include mat.system-level-typography($yallo-theme);
}

////////////////// ENDS Themes definition ///////////////////
