:root {
  --sys-primary: var(--color-primary-default-from);
  --sys-surface-tint: var(--color-secondary-default);
  --sys-on-primary: var(--color-text-primary-inverse);
  --sys-primary-container: var(--color-secondary-light);
  --sys-on-primary-container: var(--color-text-primary);

  --sys-secondary: var(--color-secondary-dark);
  --sys-on-secondary: var(--color-text-primary-inverse);
  --sys-secondary-container: var(--color-tertiary-light);
  --sys-on-secondary-container: var(--color-tertiary-dark);

  --sys-tertiary: var(--color-tertiary-default);
  --sys-on-tertiary: var(--color-text-secondary);
  --sys-tertiary-container: var(--color-tertiary-light);
  --sys-on-tertiary-container: var(--color-tertiary-dark);

  --sys-error: var(--color-text-negative);
  --sys-on-error: var(--color-text-primary-inverse);
  --sys-error-container: var(--color-background-negative-light);
  --sys-on-error-container: var(--color-text-negative);

  --sys-background: var(--color-background-primary);
  --sys-on-background: var(--color-text-primary);

  --sys-surface: var(--color-background-primary);
  --sys-on-surface: var(--color-text-primary);
  --sys-surface-variant: var(--color-background-secondary);
  --sys-on-surface-variant: var(--color-text-secondary);

  --sys-outline: var(--color-border-primary);
  --sys-outline-variant: var(--color-border-secondary);

  --sys-shadow: var(--basic-black);
  --sys-scrim: var(--basic-white);

  --sys-inverse-surface: var(--color-background-primary-inverse);
  --sys-inverse-on-surface: var(--color-text-primary-inverse);
  --sys-inverse-primary: var(--color-secondary-light);

  --sys-primary-fixed: var(--color-tertiary-default);
  --sys-on-primary-fixed: var(--color-text-primary-inverse);
  --sys-primary-fixed-dim: var(--color-tertiary-dark);
  --sys-on-primary-fixed-variant: var(--color-text-secondary-inverse);

  --sys-secondary-fixed: var(--color-secondary-default);
  --sys-on-secondary-fixed: var(--color-text-primary-inverse);
  --sys-secondary-fixed-dim: var(--color-secondary-dark);
  --sys-on-secondary-fixed-variant: var(--color-secondary-dark);

  --sys-tertiary-fixed: var(--color-tertiary-default);
  --sys-on-tertiary-fixed: var(--color-text-primary-inverse);
  --sys-tertiary-fixed-dim: var(--color-tertiary-dark);
  --sys-on-tertiary-fixed-variant: var(--color-text-secondary-inverse);

  --sys-surface-dim: var(--color-background-tertiary);
  --sys-surface-bright: var(--color-background-primary);
  --sys-surface-container-lowest: var(--color-background-primary);
  --sys-surface-container-low: var(--color-background-secondary);
  --sys-surface-container: var(--color-background-primary);
  --sys-surface-container-high: var(--color-background-primary);
  --sys-surface-container-highest: (--color-background-tertiary);
}
