@use 'tailwindcss/base';
@use 'tailwindcss/components';
@use 'tailwindcss/utilities';

:root {
  --mat-tab-header-label-text-size: 1.3rem;
  --mat-tab-header-active-label-text-color: var(--color-dark-gray);
}

.product-features {
  margin: 0;
  list-style: none;

  li {
    margin-top: 1rem;

    &:first-child {
      margin-top: 0;
    }
  }

  b {
    display: block;
  }
}

body {
  margin: 0;
}

//TEXT COLORS
.text {
  &-dark {
    color: var(--color-dark);
  }
  &-gray-1 {
    color: var(--color-gray-1);
  }
  &-gray-2 {
    color: var(--color-gray-2);
  }
  &-red {
    color: var(--color-red);
  }
  &-primary {
    color: var(--color-primary);
  }
  &-light {
    color: var(--color-light);
  }
  &-featured {
    color: var(--color-featured);
  }
  &-color-nav {
    color: var(--color-nav);
  }
  &-color-nav-active {
    color: var(--color-nav-active);
  }
  &-color-footer {
    color: var(--color-footer);
  }
  &-color-footer-active {
    color: var(--basic-white);
  }
  &-light-gray {
    color: var(--color-light-gray);

    b {
      color: var(--color-dark-gray);
    }
  }
  &-light-black {
    color: var(--color-light-black);
  }
  &-color-product-box-description {
    color: var(--color-product-box-description);
  }
  &-color-title {
    color: var(--color-title);
  }
  &-color-text-card-list {
    color: var(--color-text-card-list);
  }
  &-color-table-header-rows {
    color: var(--color-table-header-rows);
  }
  &-color-table-header-columns {
    color: var(--color-table-header-columns);
  }
  &-color-centered-accordion-titles {
    color: var(--color-centered-accordion-titles);
  }
}

//FONT-SIZES
h1 {
  @apply text-4xl md:text-5xl xl:text-6xl 2xl:text-7xl;
}
h2 {
  @apply text-2xl md:text-3xl xl:text-4xl 2xl:text-5xl;
}
h3 {
  @apply text-xl md:text-2xl xl:text-3xl 2xl:text-4xl;
}
h4 {
  @apply text-lg md:text-xl xl:text-2xl 2xl:text-3xl;
}
h5 {
  @apply text-md md:text-lg xl:text-xl 2xl:text-2xl;
}
h6 {
  @apply text-md xl:text-lg 2xl:text-xl;
}
p {
  @apply text-base;
}
.header-title {
  @apply lg:text-6xl md:text-4xl text-2xl;
}
.section-title {
  @apply lg:text-xl md:text-lg text-base font-bold;
}
.field-label {
  @apply text-xs md:text-sm lg:text-base;
}
.field-text {
  @apply text-sm md:text-base lg:text-lg;
}
.info-text {
  @apply text-sm md:text-base lg:text-base;
}

//FONT-WEIGHTS
h1 {
  @apply font-extrabold;
}
h2 {
  @apply xl:font-semibold;
}
h5 {
  @apply font-bold md:font-normal;
}
h6 {
  @apply md:font-bold xl:font-normal;
}

p {
  @apply font-normal;
}
strong,
b {
  @apply font-bold;
}

//OFFER BOX CONTENTS
.offer-box,
.offer-box-light {
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  ul,
  li {
    @apply font-normal;
  }
}

.offer-box ngx-dynamic-hooks {
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  ul,
  li {
    @apply mb-4;
  }
}

//BOXES
.sales-box-no-shadow {
  background: var(--basic-white);
  border-radius: var(--border-radius-product-boxes);
}
.sales-box {
  @extend .sales-box-no-shadow;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
}
.top-border-radius {
  border-top-left-radius: var(--border-radius-product-boxes);
  border-top-right-radius: var(--border-radius-product-boxes);
}
.bottom-border-radius {
  border-bottom-left-radius: var(--border-radius-product-boxes);
  border-bottom-right-radius: var(--border-radius-product-boxes);
}
.offer-box-light-font-size {
  font-size: 1.125rem;
}
.lg-left-border-radius {
  @media (min-width: 1024px) {
    border-top-left-radius: var(--border-radius-product-boxes);
    border-bottom-left-radius: var(--border-radius-product-boxes);
  }
}
.lg-right-border-radius {
  @media (min-width: 1024px) {
    border-top-right-radius: var(--border-radius-product-boxes);
    border-bottom-right-radius: var(--border-radius-product-boxes);
  }
}

//BACKGROUND COLORS
.bg {
  &-primary-gradient {
    background: var(--color-primary-default);
  }
  &-color {
    &-featured {
      background-color: var(--color-featured);
    }
    &-black {
      background-color: var(--color-black);
    }
    &-dark {
      background-color: var(--color-dark);
    }
    &-gray-2 {
      background-color: var(--color-gray-2);
    }
    &-light {
      background-color: var(--color-light);
    }
    &-red {
      background-color: var(--color-red);
    }
    &-white {
      background-color: var(--basic-white);
    }
    &-footer {
      background-color: var(--color-bg-footer);
    }
    &-primary {
      background-color: var(--color-primary);
    }
    &-featured {
      background-color: var(--color-featured);
    }
    &-blue {
      background-color: var(--color-blue-2);
    }
    &-purple {
      background-color: var(--color-midnight-purple);
    }
    &-primary-gradient {
      background-color: var(--color-primary-default);
    }
  }
}

//BORDER COLORS
.border-color {
  &-gray-4 {
    border-color: var(--color-gray-4);
  }
  &-gray-3 {
    border-color: var(--color-gray-3);
  }
}

//FOOTER
.footer__link-text--hover {
  &:hover {
    color: var(--color-footer-active);
  }
}

// MODAL
body.modal-popup-open {
  /* body overflow is hidden to hide main scrollbar when modal window is open */
  overflow: hidden;
}

// OFFER BOX STYTES
.offer-box-font-size {
  &_medium {
    font-size: 1.4rem;
    font-weight: bold;
    @media (max-width: 1024px) {
      font-size: 1.2rem;
    }
  }
  &_large {
    font-size: 2rem;
    font-weight: bold;
    @media (max-width: 1024px) {
      font-size: 1.5rem;
    }
  }
}
.check {
  text-align: left;
  padding-left: 0.7em;
  position: relative;
  display: block;
}

//PRISMIC STYLES
[data-source='prismic'] {
  a {
    color: var(--color-link-default);

    &:hover {
      color: var(--color-link-hover);
    }

    &:active,
    &:visited {
      color: var(--color-link-active);
    }
  }
  ul {
    @apply list-disc;
  }
  check {
    text-align: left;
    padding-left: 1.4em;
    position: relative;
    display: block;

    &.inline {
      display: inline-block;
    }

    &:before {
      position: absolute;
      left: 0;
      top: 0.2em;
      width: 1.1em;
      height: 1.1em;
      content: '';
      background-image: var(--check-mark);
      background-size: contain;
      background-position: left center;
      background-repeat: no-repeat;
    }

    &.tvIcon:before {
      position: absolute;
      left: 0;
      top: 0.2em;
      width: 1.1em;
      height: 1.1em;
      content: '';
      background-image: pink;
      background-size: contain;
      background-position: left center;
      background-repeat: no-repeat;
    }
    &.center-vertically:before {
      top: 45%;
      transform: translateY(-50%);
    }
  }
}

//LINE CHECK STYLES
.line-check-box {
  &__input {
    @apply flex items-center border border-solid rounded-lg bg-white box-border h-14;
    &-text {
      @apply focus-within:outline-none;
    }
    &--invalid {
      @apply border border-solid border-red-600;
    }
    &--disabled {
      background-color: var(--color-gray-5);
    }
  }
  &__select:focus-visible {
    @apply outline-none;
  }
  &__button {
    @apply w-full h-14;
    .button-text {
      transition: all 0.2s;
    }
    &--disabled {
      @apply opacity-50 cursor-default;
    }
    &--loading {
      .button--text {
        visibility: hidden;
        opacity: 0;
      }
      &::after {
        content: '';
        position: absolute;
        width: 2rem;
        height: 2rem;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 4px solid transparent;
        border-top-color: #ffffff;
        border-radius: 50%;
        animation: button-loading-spinner 1s ease infinite;
      }
    }
  }
  &__back-button {
    @apply h-14 text-lg font-bold;
  }
}
input::placeholder {
  .line-check-box__input {
    color: var(--color-gray-2);
  }
}
input[disabled] {
  .line-check-box__input {
    background-color: white;
  }
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

// CUSTOM THEME
body.custom-theme {
  .kitsune-nav-bar,
  .kitsune-nav-bar--active,
  .kitsune-nav-bar .kitsune-nav-gradient {
    background: var(--color-theme);
  }
  .curved-box,
  .curved-box.bg-linear,
  .newsletter,
  .bg-lebara {
    background-image: var(--theme-gradient);
  }
  .newsletter .separator {
    background-image: var(--light-gradient) !important;
  }
  .bg-color-footer {
    background-color: var(--color-theme);
  }
  .text-color-footer {
    color: var(--basic-white);
  }
  .logo svg {
    fill: var(--basic-white) !important;
  }
}

// tv THEME
body.tv-theme {
  .kitsune-nav-bar,
  .kitsune-nav-bar--active,
  .kitsune-nav-bar .kitsune-nav-gradient {
    background: var(--color-midnight);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
  }
  .curved-box,
  .newsletter {
    color: var(--color-midnight);
    background: var(--tv-gradient);
  }

  .newsletter .separator {
    background-image: var(--tv-gradient-separator) !important;
  }
  .bg-color-footer {
    background-color: var(--color-midnight);
  }
  .text-color-footer {
    color: var(--basic-white);
  }
  .logo svg {
    fill: var(--color-primary) !important;
  }
}

html body iframe#LeadboosterContainer {
  z-index: 999999999 !important;
}

.margin-on-scroll {
  scroll-margin-top: 5rem;
}

.custom-dialog-container {
  @apply opacity-0;
  display: none;
}

.cdk-overlay-container {
  z-index: 99 !important;
}

// ANGULAR MATERIAL
// Fix the bug that prevented to focus on a field by clicking on its floating label
.mat-form-field-appearance-outline {
  .mat-mdc-input-element {
    position: relative;
    z-index: 1;
  }
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  // Remove the lines underneath form fields
  display: none;
}

.cdk-overlay-pane .mat-menu-panel {
  min-width: 95px;
}

// Style to fix the weird border with angular material
.mdc-notched-outline__notch {
  border-right: none !important;
}

.mat-mdc-form-field {
  @apply mb-2 max-lg:mb-6;
}

mat-tab-header .mdc-tab--active {
  @apply font-extrabold;
}

.mat-mdc-tab-group {
  --mat-tab-header-active-ripple-color: transparent;
  --mat-tab-header-inactive-ripple-color: transparent;
}

.tabs .mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper {
  --mat-mdc-form-field-label-offset-x: 32px !important;
}

.mdc-text-field--filled {
  --mdc-filled-text-field-container-shape: 50px;
  border-bottom-right-radius: var(--mdc-filled-text-field-container-shape) !important;
  border-bottom-left-radius: var(--mdc-filled-text-field-container-shape) !important;
}
