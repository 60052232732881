:root {
  --basic-black: #000000;
  --basic-white: #ffffff;
  --basic-white-75: #ffffff75;

  --blue-50: #f0f6fa;
  --blue-100: #d3e6f0;
  --blue-200: #82bdd9;
  --blue-300: #3d9ecc;
  --blue-300-2: rgba(104, 201, 232, 0.2);
  --blue-400: #0081bf;
  --blue-500: #0c7cb2;
  --blue-500-2: #0c7cb21f;
  --blue-500-3: #0c7cb214;
  --blue-500-4: #0c7cb229;
  --blue-600: #0a6a99;
  --blue-700: #005580;
  --blue-800: #004669;
  --blue-900: #00354f;

  --teal-50: #dff7f7;
  --teal-100: #c6f7f7;
  --teal-200: #9aeded;
  --teal-300: #7be0e0;
  --teal-400: #00cccc;
  --teal-500: #04bdc6;
  --teal-600: #009999;
  --teal-700: #008080;
  --teal-800: #006666;
  --teal-900: #004d4d;

  --neutral-50: #fdfeff;
  --neutral-100: #fafcff;
  --neutral-200: #f7f9fc;
  --neutral-300: #f2f5fa;
  --neutral-400: #d5dbe5;
  --neutral-400-2: rgba(213, 219, 229, 0.2);
  --neutral-500: #a5b0c4;
  --neutral-600: #778399;
  --neutral-700: #636d80;
  --neutral-800: #364259;
  --neutral-900: #2e394d;

  --green-50: #e8faf6;
  --green-100: #aaf2e1;
  --green-200: #79f2d5;
  --green-300: #17e5b4;
  --green-400: #00cf9d;
  --green-400-2: rgba(0, 207, 157, 0.2);
  --green-500: #00b288;
  --green-600: #009975;
  --green-700: #008061;
  --green-800: #00664e;
  --green-900: #004d3a;

  --yellow-50: #fff7e1;
  --yellow-100: #fff0c7;
  --yellow-200: #ffeab6;
  --yellow-300: #ffdc7a;
  --yellow-400: #ffd561;
  --yellow-500: #ffca47;
  --yellow-500-2: rgba(255, 202, 71, 0.2);
  --yellow-600: #ffb600;
  --yellow-800: #755201;
  --yellow-900: #523c08;

  --red-50: #fff0f1;
  --red-100: #fcb6b6;
  --red-200: #e57381;
  --red-300: #e55c6c;
  --red-400: #e54558;
  --red-400-2: rgba(229, 69, 88, 0.2);
  --red-500: #e52e44;
  --red-600: #e5152e;
  --red-700: #cc1229;
  --red-800: #990e1e;
  --red-900: #660914;

  --azure-50: #d2e9fb;
  --azure-100: #99cdfa;
  --azure-200: #64b2f7;
  --azure-300: #2593f4;
  --azure-400: #0285f7;
  --azure-500: #0175da;
  --azure-600: #0b67b7;
  --azure-700: #135ea0;
  --azure-800: #154875;
  --azure-900: #19354c;

  --indigo-50: #dee2fc;
  --indigo-100: #bec5f9;
  --indigo-200: #8a97f4;
  --indigo-300: #5265ef;
  --indigo-400: #243beb;
  --indigo-500: #103fb1;
  --indigo-600: #0834a0;
  --indigo-700: #082a7d;
  --indigo-800: #061060;
  --indigo-900: #040b44;

  --pink-50: #fdf2f6;
  --pink-100: #f6dfe7;
  --pink-200: #ebb7ca;
  --pink-300: #ea90b1;
  --pink-400: #e5578b;
  --pink-500: #e12369;
  --pink-600: #c81e5c;
  --pink-700: #9c1648;
  --pink-800: #701033;
  --pink-900: #470a21;
}
